import { withTranslations } from 'hooks/useTranslations'
import { graphql, useStaticQuery } from 'gatsby'

declare global {
  interface Snippets {
    'partials-ProductReviews': {
      customer_reviews: void
      total_ratings_text: {
        count: string
      }
      histogram_stars: void
      verified_buyer: void
      verified_purchase: void
      service_reply: void
      article_number: void
      product_reviews_show_in_original_language: void
      product_reviews_show_translation: void
      product_reviews_location_de_DE: void
      product_reviews_location_de_AT: void
      product_reviews_location_de_CH: void
      product_reviews_location_fr_FR: void
      product_reviews_location_fr_BE: void
      product_reviews_location_fr_CH: void
      product_reviews_location_en: void
      product_reviews_location_es_ES: void
      product_reviews_location_da_DK: void
      product_reviews_location_sv_SE: void
      product_reviews_location_nb_NO: void
      product_reviews_location_pt_PT: void
      product_reviews_location_nl_BE: void
      product_reviews_location_nl_NL: void
      product_reviews_location_it_IT: void
      product_reviews_location_hr_HR: void
    }
  }
}

export default (Component) =>
  withTranslations(Component, () =>
    useStaticQuery(graphql`
      query partials_ProductReviews_translations {
        customer_reviews: snippet(
          group: "partials-ProductReviews"
          name: "customer_reviews"
        )
        total_ratings_text: snippet(
          group: "partials-ProductReviews"
          name: "total_ratings_text"
        )
        histogram_stars: snippet(
          group: "partials-ProductReviews"
          name: "histogram_stars"
        )
        verified_buyer: snippet(
          group: "partials-ProductReviews"
          name: "verified_buyer"
        )
        verified_purchase: snippet(
          group: "partials-ProductReviews"
          name: "verified_purchase"
        )
        service_reply: snippet(
          group: "partials-ProductReviews"
          name: "service_reply"
        )
        article_number: snippet(
          group: "partials-ProductReviews"
          name: "article_number"
        )
        product_reviews_show_in_original_language: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_show_in_original_language"
        )
        product_reviews_show_translation: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_show_translation"
        )
        product_reviews_location_de_DE: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_de_DE"
        )
        product_reviews_location_de_AT: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_de_AT"
        )
        product_reviews_location_de_CH: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_de_CH"
        )
        product_reviews_location_fr_FR: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_fr_FR"
        )
        product_reviews_location_fr_BE: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_fr_BE"
        )
        product_reviews_location_fr_CH: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_fr_CH"
        )
        product_reviews_location_en: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_en"
        )
        product_reviews_location_es_ES: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_es_ES"
        )
        product_reviews_location_da_DK: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_da_DK"
        )
        product_reviews_location_sv_SE: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_sv_SE"
        )
        product_reviews_location_nb_NO: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_nb_NO"
        )
        product_reviews_location_pt_PT: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_pt_PT"
        )
        product_reviews_location_nl_BE: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_nl_BE"
        )
        product_reviews_location_nl_NL: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_nl_NL"
        )
        product_reviews_location_it_IT: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_it_IT"
        )
        product_reviews_location_hr_HR: snippet(
          group: "partials-ProductReviews"
          name: "product_reviews_location_hr_HR"
        )
      }
    `)
  )
