import React from 'react'
import * as at from './const'

export const setSearchValue = (value: string) => ({
  type: at.SET_SEARCH_VALUE,
  payload: value
})

export const toggleNavi = () => ({
  type: at.TOGGLE_NAVI
})

export const setSidebarCategory = (category: string | null) => ({
  type: at.SET_SIDEBAR_CATEGORY,
  payload: category
})

export const setCurrentCategory = (category: string | null) => ({
  type: at.SET_CURRENT_CATEGORY,
  payload: category
})

export const clear = (exception?: string) => ({
  type: at.CLEAR,
  payload: exception
})

export const setModalContent = (content: React.ReactElement | null) => ({
  type: at.SET_MODAL_CONTENT,
  payload: content
})

export const setOptIn = () => ({
  type: at.SET_OPT_IN
})

export const showReviews = (containerID: string) => ({
  type: at.SHOW_REVIEWS,
  meta: { containerID }
})
//WDV-3313 NewsletterIntentLayer
export const incrementLocationChangeCounter = () => ({
  type: at.INCREMENT_LOCATION_CHANGE_COUNTER
})

export const registerNewsletterNL = () => ({
  type: at.REGISTER_NEWSLETTER_NL
})

export const setIntentVisible = (payload: {
  visible: boolean
  source: '5_pageviews' | 'exit_intent' | 'inactivity'
}) => ({
  type: at.SET_INTENT_VISIBLE,
  payload: payload
})

//WDV-3313 NewsletterIntentLayer

export type SetSearchValue = ReturnType<typeof setSearchValue>
export type ToggleNavi = ReturnType<typeof toggleNavi>
export type SetSidebarCategory = ReturnType<typeof setSidebarCategory>
export type SetCurrentCategory = ReturnType<typeof setCurrentCategory>
export type Clear = ReturnType<typeof clear>
export type SetModalContent = ReturnType<typeof setModalContent>
export type SetOptIn = ReturnType<typeof setOptIn>
export type ShowReviews = ReturnType<typeof showReviews>
//WDV-3313 NewsletterIntentLayer

export type IncrementLocationChangeCounter = ReturnType<
  typeof incrementLocationChangeCounter
>
export type SetIntentVisible = ReturnType<typeof setIntentVisible>

export type RegisterNewsletterNL = ReturnType<typeof registerNewsletterNL>
//WDV-3313 NewsletterIntentLayer
export type Action =
  | SetSearchValue
  | ToggleNavi
  | SetSidebarCategory
  | SetCurrentCategory
  | Clear
  | SetModalContent
  | SetOptIn
  | ShowReviews
  //WDV-3313 NewsletterIntentLayer
  | IncrementLocationChangeCounter
  | SetIntentVisible
  | RegisterNewsletterNL
//WDV-3313 NewsletterIntentLayer
