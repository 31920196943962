/* eslint-disable @kaminrunde/firescout/jsx-expression-needs-state */
import * as React from 'react'
import config from 'config'
import B2cSwitch from 'theme/atoms/B2cSwitch'
import styled from 'styled-components'
import priceStr from 'utils/priceString'
import withTranslations from './withTranslations'
import useTranslations from 'hooks/useTranslations'
import Link from '../Link'
import * as gt from 'utils/productShapeTypes'
import theme from 'theme'

type Props = {
  logic:
    | 'piece'
    | 'pack'
    | 'packPseudo'
    | 'piecePseudo'
    | 'base'
    | 'productCheapestPiece'
  prices: gt.ProductPriceDefinition
  amount?: number
  scalePrice?: gt.ProductPriceRulesDefinition
  'data-cy-state'?: string
  'data-cy-handle'?: string
  squareM?: number
  additionalPrice?: number
  priceRules?: gt.ProductPriceRulesDefinition
}

/**
 * Function will be extended with more logic types, currently only working for pack
 * @param logic
 * @returns netKey and grossKey
 */
function mapPriceLogicToPricesKey(logic: Props['logic']): {
  netKey: string
  grossKey: string
} {
  switch (logic) {
    case 'pack':
      return {
        netKey: 'productCheapestPackPriceNet',
        grossKey: 'productCheapestPackPriceGross'
      }
    case 'piece':
      return {
        netKey: 'productCheapestPiecePriceNet',
        grossKey: 'productCheapestPiecePriceGross'
      }
    default:
      throw new Error(`Unknown logic: ${logic}`)
  }
}

const getPrices = (props: Props) => {
  const amount = props.amount || 1

  if (props.scalePrice) {
    if (props.scalePrice.length > 1) {
      const prices = props.scalePrice.find(
        (sPrice) =>
          sPrice.from <= amount &&
          (sPrice.to === 'beliebig' ||
            (typeof sPrice.to != 'string' && amount <= sPrice.to))
      )
      if (prices) return prices
    }
  }
  return props.prices
}
/**
 * if you want to check if strike price exists call `Price.hasStrikePrice`
 */
export default withTranslations(Price)

function Price(props: Props) {
  let amount = props.amount || 1
  const prices = getPrices(props)
  if (!prices) return null
  const net = prices[props.logic + 'PriceNet']
  const gross = prices[props.logic + 'PriceGross']
  if (props.logic == 'piece' || props.logic == 'piecePseudo') amount = 1
  const isStrikePrice = PriceHasStrikePrice(
    {
      prices: props.prices,
      priceRules: props.priceRules ? props.priceRules : []
    },
    amount
  )

  return (
    <PriceWrapper $isStrikePrice={isStrikePrice}>
      {<B2cSwitch.B2B>{priceStr(net * amount)}</B2cSwitch.B2B>}
      {<B2cSwitch.B2C>{priceStr(gross * amount)}</B2cSwitch.B2C>}
    </PriceWrapper>
  )
}

export function CustomTailorPrice(props: Props) {
  let amount = props.amount || 1
  const prices = getPrices(props)
  if (!prices || !props.squareM) return null
  const net = prices[props.logic + 'PriceNet']
  const gross = prices[props.logic + 'PriceGross']
  if (props.logic == 'piece' || props.logic == 'piecePseudo') amount = 1
  let addPrice = props.squareM
  if (props.additionalPrice) {
    addPrice = addPrice * props.additionalPrice
  }
  const endPriceNet = Math.ceil(net * 100 * addPrice) / 100
  const endPriceGross =
    (Math.ceil(net * 100 * addPrice) / 100) * getTax(gross, net)
  return (
    <>
      {<B2cSwitch.B2B>{priceStr(endPriceNet * amount)}</B2cSwitch.B2B>}
      {<B2cSwitch.B2C>{priceStr(endPriceGross * amount)}</B2cSwitch.B2C>}
    </>
  )
}

export function PiecePriceString(props: Props) {
  const prices = getPrices(props)
  if (!prices) return null
  if (!prices.piecePriceNetString || !prices.piecePriceGrossString) return null

  return (
    <>
      {<B2cSwitch.B2B>{prices.piecePriceNetString}</B2cSwitch.B2B>}
      {<B2cSwitch.B2C>{prices.piecePriceGrossString}</B2cSwitch.B2C>}
    </>
  )
}

export function Cheapest(props: Props) {
  const prices = getPrices(props)
  if (!prices) return null
  const { netKey, grossKey } = mapPriceLogicToPricesKey(props.logic)
  const net = prices[netKey]
  const gross = prices[grossKey]
  return (
    <>
      {<B2cSwitch.B2B>{priceStr(net)}</B2cSwitch.B2B>}
      {<B2cSwitch.B2C>{priceStr(gross)}</B2cSwitch.B2C>}
    </>
  )
}

export function PriceReduction(props: Props) {
  const prices = getPrices(props)
  const net = prices[props.logic + 'PriceNet']
  const gross = prices[props.logic + 'PriceGross']
  const netPseudo = prices[props.logic + 'PseudoPriceNet']
  const grossPseudo = prices[props.logic + 'PseudoPriceGross']
  return (
    <>
      {Boolean(net) && (
        <B2cSwitch.B2B>{reduction(net, netPseudo)}</B2cSwitch.B2B>
      )}
      {Boolean(gross) && (
        <B2cSwitch.B2C>{reduction(gross, grossPseudo)}</B2cSwitch.B2C>
      )}
    </>
  )
}

export function PriceStrike(props: Props) {
  let amount = props.amount || 1
  const prices = getPrices(props)
  const net = prices[props.logic + 'PriceNet']
  const gross = prices[props.logic + 'PriceGross']
  const netPseudo = prices[props.logic + 'PseudoPriceNet']
  const grossPseudo = prices[props.logic + 'PseudoPriceGross']
  if (props.logic == 'piece' || props.logic == 'piecePseudo') amount = 1
  return (
    <>
      {Boolean(net) && (
        <B2cSwitch.B2B>
          <StrikePrice>{priceStr(netPseudo * amount)}</StrikePrice>
        </B2cSwitch.B2B>
      )}
      {Boolean(gross) && (
        <B2cSwitch.B2C>
          <StrikePrice>{priceStr(grossPseudo * amount)}</StrikePrice>
        </B2cSwitch.B2C>
      )}
    </>
  )
}

export const PriceB2BGrossInfo = withTranslations(function PriceB2BGrossInfo(
  props: Props
) {
  const t = useTranslations<'prices'>()
  let amount = props.amount || 1
  const prices = getPrices(props)
  const gross = prices[props.logic + 'PriceGross']
  if (props.logic === 'piece') amount = 1

  return (
    <>
      {Boolean(gross) && (
        <B2cSwitch.B2B>
          ({t('incl_tax')} {priceStr(gross * amount)}{' '}
          {t('excl_delivery_costs_with_link', {
            DELIVERYCOSTS: '-',
            __transformers: {
              DELIVERYCOSTS: (_, vars) => (
                <LinkText>
                  {
                    <Link to={vars.DELIVERYCOSTS_link}>
                      {vars.DELIVERYCOSTS_label}
                    </Link>
                  }
                </LinkText>
              )
            }
          })}
          )
        </B2cSwitch.B2B>
      )}
    </>
  )
})

const getTax = (gross, net) => {
  return Math.round((gross / net) * 100) / 100
}

export const CustomTailorB2BGrossInfo = withTranslations(
  function PriceB2BGrossInfo(props: Props) {
    const t = useTranslations<'prices'>()
    let amount = props.amount || 1
    const prices = getPrices(props)
    const net = prices[props.logic + 'PriceNet']
    const gross = prices[props.logic + 'PriceGross']
    if (props.logic === 'piece') amount = 1
    if (!props.squareM) return null
    let addPrice = props.squareM
    if (props.additionalPrice) {
      addPrice = addPrice * props.additionalPrice
    }

    const endPriceGross =
      (Math.ceil(net * 100 * addPrice) / 100) * amount * getTax(gross, net)
    return (
      <>
        {Boolean(gross) && (
          <B2cSwitch.B2B>
            ({t('incl_tax')} {priceStr(endPriceGross)})
          </B2cSwitch.B2B>
        )}
      </>
    )
  }
)

export const PriceB2CNetInfo = withTranslations(function PriceB2CNetInfo() {
  const t = useTranslations<'prices'>()
  return (
    <B2cSwitch.B2C>
      ({t('incl_tax')}{' '}
      {t('excl_delivery_costs_with_link', {
        DELIVERYCOSTS: '-',
        __transformers: {
          DELIVERYCOSTS: (_, vars) => (
            <LinkText>
              {
                <Link to={vars.DELIVERYCOSTS_link}>
                  {vars.DELIVERYCOSTS_label}
                </Link>
              }
            </LinkText>
          )
        }
      })}
      )
    </B2cSwitch.B2C>
  )
})

export const EcoTaxSum = (props: {
  prices: { ecoTaxNet: number; ecoTaxGross: number }
}) => {
  return (
    <span>
      <B2cSwitch.B2B>{priceStr(props.prices.ecoTaxNet || 0)}</B2cSwitch.B2B>
      <B2cSwitch.B2C>{priceStr(props.prices.ecoTaxGross || 0)}</B2cSwitch.B2C>
    </span>
  )
}

export const EcoTax = withTranslations(function EcoTax(props: Props) {
  const amount = props.amount || 1

  const t = useTranslations<'prices'>()
  return (
    <span data-cy-state={props['data-cy-state']}>
      <B2cSwitch.B2B>
        {t('eco_tax', {
          ecoTax: priceStr((props.prices.ecoTaxNet || 0) * amount)
        })}
      </B2cSwitch.B2B>
      <B2cSwitch.B2C>
        {t('eco_tax', {
          ecoTax: priceStr((props.prices.ecoTaxGross || 0) * amount)
        })}
      </B2cSwitch.B2C>
    </span>
  )
})

export function PriceBaseprice(props: Props) {
  return (
    <span
      data-cy-state={props['data-cy-state']}
      data-cy-handle={props['data-cy-handle']}
    >
      <B2cSwitch.B2B>{props.prices.referencePriceNetString}</B2cSwitch.B2B>
      <B2cSwitch.B2C>{props.prices.referencePriceGrossString}</B2cSwitch.B2C>
    </span>
  )
}

export function PriceHasStrikePrice({ prices, priceRules }, amount) {
  const calcPrices = getPrices({
    prices,
    scalePrice: priceRules,
    amount,
    logic: 'piece',
    priceRules: priceRules
  })
  if (
    calcPrices.packPseudoPriceNet !== 0 &&
    calcPrices.piecePseudoPriceNet !== 0
  ) {
    return true
  }
  return false
}

export const PriceFrom = withTranslations(function PriceFrom() {
  const t = useTranslations<'prices'>()
  return t('from')
})

export const PriceTo = withTranslations(function PriceTo() {
  const t = useTranslations<'prices'>()
  return t('to')
})

export const PriceExclTax = withTranslations(function PriceExclTax() {
  const t = useTranslations<'prices'>()
  return t('excl_tax')
})

export const reduction = (
  price: number | string,
  pseudoPrice: number | string
) => {
  price = typeof price === 'string' ? parseFloat(price) : price
  pseudoPrice =
    typeof pseudoPrice === 'string' ? parseFloat(pseudoPrice) : pseudoPrice
  const reduction = 1 - price / pseudoPrice

  if (parseInt((reduction * 100).toString()) === 0) return ''

  return (
    '-' +
    reduction.toLocaleString(config.i18n.locale, {
      style: 'percent'
    })
  )
}

const StrikePrice = styled.span`
  text-decoration: line-through;
  color: gray;
`

const PriceWrapper = styled.span<{ $isStrikePrice: boolean }>`
  color: ${(p) => (p.$isStrikePrice ? theme.colors.accent.red : '')};
`

const LinkText = styled.span`
  text-decoration: underline;
`
