import * as React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import config from 'config'
import useAccount from 'hooks/account/useAccount'
import Button from 'theme/atoms/Button'
import Input from 'theme/atoms/Input'
import Faktor from 'assets/factor.svg'
import { useIntentLayer } from 'modules/ui'
import useNewsletter from 'hooks/useNewsletter'
import { registerNewsletterNL } from 'modules/ui/actions'
import { push } from 'features/tracking/datalayer'
import Close from 'assets/menu-close.svg'
import { dispatchEvent } from 'redux-ruleset'

declare global {
  interface RulesetDispatchEvents {
    'newsletterIntentLayer/IvalidMail': { type: 'InvalidMail' }
  }
}

export default function NewsletterIntentLayer() {
  const account = useAccount()
  const newsletter = useNewsletter()
  const isB2b = account.channel === 'b2b'
  const [error, setError] = React.useState<string | null>(null)
  const [email, setEmail] = React.useState('')
  const intentLayer = useIntentLayer()

  const handleSubmit = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!emailRegex.test(email)) {
      setError('Adresse e-mail non valide')

      dispatchEvent({ type: 'InvalidMail' })
      return
    }

    newsletter.createContactWithOptIn({
      channel: account.channel,
      email: email,
      subscriptionSource: 'Newsletterlayer'
    })
    intentLayer.registerNewsletterNL()
  }

  const handleClose = () => {
    push({
      event: 'genericEvent',
      eventname: 'newsletter_layer',
      action: 'close'
    })

    intentLayer.hideIntentLayer()
  }

  React.useEffect(() => {
    if (account.loggedIn) {
      newsletter.fetchOptInStatus()
    }
  }, [])

  React.useEffect(() => {
    if (newsletter.optInStatus) {
      registerNewsletterNL()
    }
  }, [newsletter.optInStatus])

  if (
    !intentLayer.visible ||
    !config.abTests.wdv3313NewsletterIntentLayer ||
    newsletter.optInStatus
  )
    return null
  return (
    <Wrapper $isB2B={isB2b} data-cy-ctx="app/NewsletterIntentLayer">
      <div className="modal">
        <div className="image-container">
          <img
            src={
              'https://res.cloudinary.com/lusini/image/upload/f_auto,q_70/v1/application/NewsletterPopupAB/emgqvpuormd6si2henol'
            }
            alt={'image'}
          />
          <img
            className="reduction"
            src="https://res.cloudinary.com/lusini/image/upload/v1719564168/application/NewsletterPopupAB/fstm9fb1gvvenbnosa8t.svg"
            alt="voucher"
          />
        </div>

        <div className="info-container">
          <div data-cy-handle="close" className="close" onClick={handleClose}>
            <Close />
          </div>
          <h2>{"Profitez d'offres exclusives pour les professionnels"}</h2>
          <ul className="benifits">
            <li>
              <Faktor />{' '}
              <span>
                <b>Économisez 20€*</b> sur votre première commande dès 200€ HT
                {" d'achat."}
              </span>
            </li>

            <li>
              <Faktor />
              Nouveautés & conseils dédiés aux professionnels
            </li>

            <li>
              <Faktor />
              Recevez gratuitement des offres exclusives et promotions
            </li>
          </ul>

          <Input
            type="email"
            className="checkout-input"
            data-cy-handle="email-input"
            value={email}
            error={error}
            placeholder={'Adresse e-mail'}
            onChange={(val) => {
              setEmail(val)
              setError(null)
            }}
          />
          <Button
            variation="primary"
            data-cy-handle="onclick"
            onClick={handleSubmit}
          >
            {"S'abonner & recevoir des offres"}
          </Button>
          <p className="info-text">
            <span>
              Voir les informations concernant notre{' '}
              <a
                href="https://lusini.com/fr-fr/aide-et-services/informations-juridiques/protection-des-donnees/"
                target="_blank"
                rel="noopener noreferrer"
              >
                politique de protection des données
              </a>
              . Vous pouvez vous désinscrire gratuitement à tout moment. *Voir
              conditions dans la newsletter.
            </span>
          </p>
        </div>
      </div>
      <div className="modal desktop">
        <div className="image-container">
          <img
            src={
              'https://res.cloudinary.com/lusini/image/upload/f_auto,q_70/v1/application/NewsletterPopupAB/plrsiebtw2qmcrmxeqjc'
            }
            alt={'image'}
          />
          <img
            className="reduction"
            src="https://res.cloudinary.com/lusini/image/upload/v1719564168/application/NewsletterPopupAB/fstm9fb1gvvenbnosa8t.svg"
            alt="voucher"
          />
        </div>

        <div className="info-container">
          <div data-cy-handle="close" className="close" onClick={handleClose}>
            X
          </div>
          <h2>{"Profitez d'offres exclusives pour les professionnels"}</h2>
          <ul className="benifits">
            <li>
              <Faktor />{' '}
              <span>
                <b>Économisez 20€*</b> sur votre première commande dès 200€ HT
                {" d'achat."}
              </span>
            </li>

            <li>
              <Faktor />
              Nouveautés & conseils dédiés aux professionnels
            </li>

            <li>
              <Faktor />
              Recevez gratuitement des offres exclusives et promotions
            </li>
          </ul>

          <Input
            type="email"
            className="checkout-input"
            data-cy-handle="email-input"
            value={email}
            error={error}
            placeholder={'Adresse e-mail'}
            onChange={(val) => {
              setEmail(val)
              setError(null)
            }}
          />
          <Button
            variation="primary"
            data-cy-handle="onclick"
            onClick={handleSubmit}
          >
            {"S'abonner & recevoir des offres"}
          </Button>
          <p className="info-text">
            <span>
              Voir les informations concernant notre{' '}
              <a
                href="https://lusini.com/fr-fr/aide-et-services/informations-juridiques/protection-des-donnees/"
                target="_blank"
                rel="noopener noreferrer"
              >
                politique de protection des données
              </a>
              . Vous pouvez vous désinscrire gratuitement à tout moment. *Voir
              conditions dans la newsletter.
            </span>
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $isB2B: boolean }>`
  display: none;
  body.ab-mode-b & {
    display: block;
  }
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${theme.zIndex.theme_app_b2xmodal_Overlay};
  background: rgba(0, 0, 0, 0.5);

  > .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 100%;
    text-align: center;
    display: flex;

    &.desktop {
      display: none;
    }

    @media (min-width: ${theme.ms.LG}px) {
      ${(p) =>
        p.$isB2B &&
        `
        display: none;
          &.desktop {
          display: flex;
          }
      `}
    }

    > .image-container {
      max-width: 50%;
      display: none;
      position: relative;
      > img:first-child {
        width: 100%;
        height: 100%;
      }
      > img.reduction {
        position: absolute;
        top: ${theme.spacing('m')};
        right: ${theme.spacing('m')};
        width: 6.25rem;
        height: 6.25rem;
      }
    }
    > .info-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: ${theme.spacing('m')};
      gap: ${theme.spacing('m')};
      > .close {
        position: absolute;
        top: ${theme.spacing('m')};
        right: ${theme.spacing('m')};
        cursor: pointer;
      }
      > h2 {
        ${theme.ty('rc-2xl', '700', 'rc-3xl')}
        margin-top:${theme.spacing('m')};
        text-align: left;
      }
      > .benifits {
        text-align: left;

        ${theme.ty('rc-s', '400', 'rc-base')}

        > li {
          margin-bottom: ${theme.spacing('xs')};
          &:last-child {
            margin-bottom: 0;
          }
          display: flex;
          align-items: start;
          padding: 0;
          > svg {
            margin-right: 0.75rem;
            min-width: 1rem;
            margin-top: 0.1875rem;
          }
        }
      }

      > .info-text {
        padding: 0;
        margin: 0;
        display: flex;
        flex: 1;
        align-items: end;
        ${theme.ty('rc-xs', '400', 'rc-s')}
        text-align: left;
        > span {
          display: block;
          > a {
            color: ${theme.colors.black};
            text-decoration: underline;
          }
        }
      }
    }
    @media (min-width: ${theme.ms.MD}px) {
      max-width: 38.25rem;
      max-height: 28.125rem;
      > .info-container {
        padding: ${theme.spacing('l')};
        > .close {
          position: absolute;
          top: ${theme.spacing('l')};
          right: ${theme.spacing('l')};
          cursor: pointer;
        }
      }
    }
    @media (min-width: ${theme.ms.LG}px) {
      max-width: 53.75rem;
      max-height: 33.75rem;
      > .info-container {
        padding: ${theme.spacing('l')};
        max-width: 50%;
      }
      > .image-container {
        display: block;
      }
    }
  }
`
