import { withTranslations } from 'hooks/useTranslations'
import { graphql, useStaticQuery } from 'gatsby'

declare global {
  interface Snippets {
    error_500: {
      headline: void
      subline: void
    }
  }
}

export default (Component) =>
  withTranslations(Component, () =>
    useStaticQuery(graphql`
      query error_500_translations {
        headline: snippet(group: "error_500", name: "headline")
        subline: snippet(group: "error_500", name: "subline")
      }
    `)
  )
